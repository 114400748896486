var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('portal-target',{attrs:{"name":"BreadCrumbs"}},[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('router-link',{attrs:{"tag":"li","to":"/records/services"}},[_c('a',[_vm._v("Kartoteki")])]),_c('router-link',{attrs:{"tag":"li","to":"/records/services"}},[_c('a',[_vm._v("Usługi")])]),_c('router-link',{staticClass:"is-active",attrs:{"tag":"li","to":{
            name: 'ServicesDetails',
            params: { id: this.$route.params.id },
          }}},[_c('a',[_vm._v(_vm._s(_vm.name))])])],1)])]),_c('div',{staticClass:"ym-toptitle-box"},[_c('fixed-header',{attrs:{"threshold":119,"fixedClass":"ym-toptitle-fixed"}},[_c('div',{staticClass:"level ym-toptitle",attrs:{"id":"toptitle"}},[_c('div',{staticClass:"level-left"},[_c('router-link',{staticClass:"button xbtn is-medium is-transparent",attrs:{"tag":"a","to":"/records/services/"}},[_c('icon',{attrs:{"name":"x"}})],1),_c('h2',{staticClass:"title level-left"},[_vm._v(_vm._s(_vm.name))])],1),_c('div',{staticClass:"buttons level-right"},[_c('button',{staticClass:"button is-medium is-light",on:{"click":_vm.removeServices}},[_c('icon',{attrs:{"name":"trash"}}),_c('span',[_vm._v("Usuń")])],1),_c('router-link',{staticClass:"button is-medium is-light",attrs:{"tag":"a","to":{
              name: 'ServicesEdit',
              params: { id: parseInt(this.$route.params.id) },
            }}},[_c('icon',{attrs:{"name":"edit"}}),_c('span',[_vm._v("Edytuj")])],1),(_vm.selectADevice)?_c('a',{staticClass:"button is-medium is-dark",on:{"click":_vm.selectDevice}},[_c('icon',{attrs:{"name":"check"}}),_c('span',[_vm._v("Wybierz")])],1):_vm._e()],1)])])],1),(_vm.services)?_c('div',{staticClass:"ym-whitebg pa40"},[_c('div',{staticClass:"columns is-variable is-6"},[(_vm.services.category)?_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v("Kategoria")]),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.services.category))])]):_vm._e(),(_vm.services.name)?_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v("Nazwa")]),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.services.name))])]):_vm._e()]),_c('div',{staticClass:"columns is-variable is-multiline is-6"},[(_vm.services.price)?_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v("Cena")]),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.services.price)+" zł")])]):_vm._e(),(_vm.services.minutes)?_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v("Czas w minutach")]),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.services.minutes))])]):_vm._e(),(_vm.services.buy_price)?_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v("Cena zakupu materiału")]),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.services.buy_price)+" zł")])]):_vm._e()]),(_vm.services.note)?_c('div',{staticClass:"columns is-variable is-6"},[_c('div',{staticClass:"column is-12"},[_c('p',{staticClass:"label-small"},[_vm._v("Notatka")]),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.services.note))])])]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }