<template>
  <section v-if="dictionary && Object.keys(dictionary).length > 0">
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/services"
            ><a>Kartoteki</a></router-link
          >
          <router-link tag="li" to="/records/services" class="is-active"
            ><a>{{
              dictionary["table_order_is_services_field_label"]
            }}</a></router-link
          >
        </ul>
      </nav>
    </portal-target>
    <div class="ym-toptitle-box">
      <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
        <div id="toptitle" class="level ym-toptitle">
          <h2 class="title level-left">
            {{ dictionary["table_order_is_services_field_label"] }}
          </h2>
          <div class="buttons level-right">
            <router-link
              tag="a"
              class="button is-medium is-dark"
              to="/records/services/add"
              ><icon name="plus"></icon><span>Dodaj</span></router-link
            >
          </div>
        </div>
      </fixed-header>
    </div>
    <div class="columns is-variable is-6 is-multiline">
      <div class="column is-12">
        <div class="ym-searchbox">
          <b-input
            ref="searchInput"
            v-model="searchQuery"
            size="is-medium"
            placeholder="SZUKAJ..."
          ></b-input>
          <div
            class="field level level-item level-right ym-searchbox-right"
            v-if="this.searchQuery"
          >
            również nieaktywni
            <b-checkbox
              v-model="searchDeleted"
              size="is-medium"
              true-value="1"
              false-value="0"
              @change.native="toggleSearchDeleted"
            >
            </b-checkbox>
          </div>
        </div>
        <ve-table
          ref="tableRef"
          border-y
          :columns="columns"
          :table-data="services"
          :cellSelectionOption="cellSelectionOption"
          :rowStyleOption="rowStyleOption"
          :event-custom-option="eventCustomOption"
          :sort-option="sortOption"
          row-key-field-name="id"
          max-height="calc(100vh - 330px)"
        />
        <div
          class="table-pagination"
          v-show="Object.keys(services).length > 0 && total >= perPage"
        >
          <ve-pagination
            :total="total"
            :page-index="page"
            :page-size="perPage"
            :page-size-option="[5, 10, 15, 25, 50, 100]"
            @on-page-number-change="onPageChange"
            @on-page-size-change="onPageSizeChange"
          />
        </div>
        <!-- <b-table
          :data="services"

          paginated
          backend-pagination
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"

          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort">


          <b-table-column field="name" label="Nazwa" sortable v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.name }}</a>
          </b-table-column>
          <b-table-column field="type" label="Kategoria" sortable v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.category }}</a>
          </b-table-column>
          <b-table-column field="serial" label="Cena w zł" sortable v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.price }}</a>
          </b-table-column>
          <b-table-column field="serial" label="Czas w minutach	" sortable v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.minutes }}</a>
          </b-table-column>
          <b-table-column field="" label="" width="100" v-slot="props">
            <span v-if="props.row.is_active === true && selectADevice === false" class="button is-small40" @click="servicesRemove(props.row.id)"><div >Usuń</div></span> 
            <router-link v-if="selectADevice === true" tag="a" class="button is-small40" :to="{ name: 'ServicesDetails', params: { id: props.row.id }}">więcej</router-link>
          </b-table-column>
          <template slot="empty" v-if="!loading">
            <section class="section">
              <div class="content" style="justify-content: center;font-weight: 600;font-size: 16px; color: #000;" v-if="!loading">
                <p><i class="mdi mdi-magnify mdi-24px" style="position: relative; top: 4px; margin-right: 16px;"></i> Brak wyników wyszukiwania.</p>
              </div>
            </section>
          </template>            
        </b-table> -->
      </div>
    </div>
  </section>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import debounce from "@/utils/debounce";
import YMmodal from "@/components/Modal.vue";

export default {
  name: "Services",
  components: {
    FixedHeader,
  },
  data() {
    return {
      services: [],
      loading: false,
      total: 0,
      sortField: "name",
      sortOrder: "asc",
      defaultSortOrder: "asc",
      page: 1,
      perPage: 50,
      searchQuery: "",
      searchDeleted: 0,
      selected: null,
      eventCustomOption: {
        bodyCellEvents: ({ row, column }) => {
          return {
            click: () => {
              const exceptions = ["action_buttons"];
              if (!exceptions.includes(column.field)) {
                this.details(row);
              }
            },
          };
        },
      },
      rowStyleOption: {
        clickHighlight: false,
        hoverHighlight: true,
      },
      cellSelectionOption: {
        // disble cell selection
        enable: false,
      },
      sortOption: {
        sortChange: (params) => {
          let numberOfEmpty = 0;
          // console.log("sortChange::", params);
          for (const param in params) {
            if (params[param]) {
              this.sortChange({ key: param, value: params[param] });
            } else {
              numberOfEmpty += 1;
            }
          }
          if (Object.keys(params).length === numberOfEmpty) this.sortChange();
          // console.log(numberOfEmpty)
        },
      },
    };
  },
  watch: {
    searchQuery() {
      this.debouncedSearchQuery();
    },
  },
  mounted() {
    // this.$refs.searchInput.$el.children[0].focus()
  },
  created() {
    this.debouncedSearchQuery = debounce(this.getServices, 500);
    this.getServices();
    document.addEventListener("keyup", this.tableNav);
    Array.from(document.querySelectorAll("table tbody tr")).forEach((node) => {
      node.classList.add("normal");
    });
  },
  destroyed() {
    document.removeEventListener("keyup", this.tableNav);
  },
  computed: {
    ...mapGetters({
      selectADevice: "orders/selectADevice",
      order: "orders/order",
      customer: "orders/customer",
      dictionary: "dictionary",
    }),
    customer_id() {
      return this.customer.id || null;
    },
    trows() {
      return document.querySelector("table").rows;
    },
    columns() {
      return [
        {
          field: "name",
          key: "name",
          title: "Nazwa",
          align: "left",
          sortBy: "",
        },
        {
          field: "category",
          key: "category",
          title: "Kategoria",
          align: "left",
          sortBy: "",
        },
        { field: "price", key: "price", title: "Cena w zł", align: "left" },
        {
          field: "minutes",
          key: "minutes",
          title: "Czas w minutach",
          align: "left",
        },
        {
          field: "buy_price",
          key: "buy_price",
          title: "Cena zakupu materiału w zł",
          align: "left",
        },
        {
          field: "note",
          key: "note",
          title: "Notatka",
          align: "left",
        },
        {
          field: "action_buttons",
          key: "action_buttons",
          title: "",
          align: "right",
          fixed: "right",
          canHide: false,
          renderBodyCell: ({ row }) => {
            let tpl;
            if (row.is_active === true && this.selectADevice === false)
              tpl = (
                <span
                  class="button is-small40"
                  on-click={() => this.servicesRemove(row.id)}
                >
                  <div>
                    <icon name="trash"></icon>
                  </div>
                </span>
              );
            if (this.selectADevice === true)
              tpl = (
                <a
                  class="button is-small40"
                  on-click={() => this.goTodetails(row)}
                >
                  więcej
                </a>
              );

            return tpl;
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      getServicesCall: "records/getServices",
      removeServicesCall: "records/removeServices",
    }),
    getServices() {
      this.loading = true;
      const {
        page,
        perPage,
        sortField,
        sortOrder,
        searchQuery,
        searchDeleted,
        customer_id,
      } = this;
      this.getServicesCall({
        page,
        perPage,
        sortField,
        sortOrder,
        searchQuery,
        searchDeleted,
        customer_id,
      })
        .then((resp) => {
          this.total = resp.page.items;
          this.services = resp.data;
        })
        .catch((error) => {
          this.services = [];
          this.total = 0;
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        })
        .finally(() => (this.loading = false));
    },
    servicesRemove(id) {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz usunąć?",
          content: `<span class='is-danger'>Usunięcia nie można cofnąć.</span> Pozostanie w utworzonych do tej pory zleceniach.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "trash",
          submit: `<span class="mal50i">Usuń</span>`,
          ymConfirm: true,
          ymOnConfirm: () => {
            this.removeServicesCall({ id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.getServices();
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    sortChange(params) {
      // console.log(params)
      if (
        (params &&
          [
            "customer_files",
            "date_of_purchase",
            "delivery_date",
            "dominant_color",
            "purchase_file",
            "repair_cost_agree",
            "repair_priority",
            "service_book",
            "time_of_purchase",
            "what_to_repair",
          ].includes(params.key)) ||
        (params && params.key.includes("input_"))
      ) {
        // console.log(params.key)
        this.services.sort((a, b) => {
          if (params === undefined) {
            return a.id > b.id ? -1 : 1;
          } else if (params.value === "desc") {
            return b["dedicated_form_fields"][params.key]
              ? a["dedicated_form_fields"][params.key]
                ? ("" + b["dedicated_form_fields"][params.key]).localeCompare(
                    a["dedicated_form_fields"][params.key]
                  )
                : 1
              : -1;
            /* return b[params.key] > a[params.key] ? 1 : -1; */
          } else if (params.value === "asc") {
            return a["dedicated_form_fields"][params.key]
              ? b["dedicated_form_fields"][params.key]
                ? ("" + a["dedicated_form_fields"][params.key]).localeCompare(
                    b["dedicated_form_fields"][params.key]
                  )
                : -1
              : 1;
            /* return a[params.key] < b[params.key] ? -1 : 1; */
          }
        });
      } else {
        this.services.sort((a, b) => {
          if (params === undefined) {
            return a.id > b.id ? -1 : 1;
          } else if (params.value === "desc") {
            return b[params.key]
              ? a[params.key]
                ? ("" + b[params.key]).localeCompare(a[params.key])
                : 1
              : -1;
            /* return b[params.key] > a[params.key] ? 1 : -1; */
          } else if (params.value === "asc") {
            return a[params.key]
              ? b[params.key]
                ? ("" + a[params.key]).localeCompare(b[params.key])
                : -1
              : 1;
            /* return a[params.key] < b[params.key] ? -1 : 1; */
          }
        });
      }
    },
    onPageChange(page) {
      this.page = page;
      if (this.searchQuery === "" || this.searchQuery === null) {
        this.getServices();
      }
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.getServices();
    },
    tableNav($event) {
      var trows = this.trows,
        trow,
        nextrow;

      switch ($event.keyCode) {
        case 13: {
          return active();
        }
        case 38: {
          return movehighlight(-1, $event);
        }
        case 40: {
          return movehighlight(1, $event);
        }
        default: {
          return true;
        }
      }

      function active() {
        document.querySelector("table tbody tr.is-selected a").click();
      }

      function movehighlight(way, e) {
        e.preventDefault && e.preventDefault();
        e.returnValue = false;
        var idx = highlightRow(true); //gets current index or null if none highlighted

        if (typeof idx === "number") {
          //there was a highlighted row
          idx += way; //increment\decrement the index value
          if (idx && (nextrow = trows[idx])) {
            return highlightRow.apply(nextrow);
          } //index is > 0 and a row exists at that index
          else if (idx) {
            return highlightRow.apply(trows[1]);
          } //index is out of range high, go to first row
          return highlightRow.apply(trows[trows.length - 1]); //index is out of range low, go to last row
        }
        return highlightRow.apply(trows[way > 0 ? 1 : trows.length - 1]); //none was highlighted - go to 1st if down arrow, last if up arrow
      }

      function highlightRow(gethighlight) {
        //now dual use - either set or get the highlighted row
        gethighlight = gethighlight === true;
        var t = trows.length;
        while (--t > -1) {
          trow = trows[t];
          if (gethighlight && trow.className === "is-selected") {
            return t;
          } else if (!gethighlight && trow !== this) {
            trow.className = "normal";
          }
        } //end while

        return gethighlight
          ? null
          : (this.className =
              this.className === "is-selected" ? "normal" : "is-selected");
      }
    },
    toggleSearchDeleted() {
      this.debouncedSearchQuery();
    },
    exit() {
      this.$store.dispatch("orders/setSelectADevice", false).then(() => {
        this.$router.push({ name: "ServiceAdd" });
      });
    },
    details(device) {
      if (this.selectADevice) {
        this.$store.dispatch("orders/setDevice", device).then(() => {
          if (this.order && this.order.id) {
            this.$router.push({
              name: "ServiceEdit",
              params: { id: parseInt(this.order.id) },
            });
          } else {
            this.$router.push({ name: "ServiceAdd" });
          }
        });
      } else {
        this.$router.push({
          name: "ServicesDetails",
          params: { id: device.id },
        });
      }
    },
    goTodetails(device) {
      this.$router.push({ name: "ServicesDetails", params: { id: device.id } });
    },
    onPageSizeChange(pageSize) {
      this.perPage = pageSize;
      this.onPageChange(1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
