<template>
  <section>
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/services"
            ><a>Kartoteki</a></router-link
          >
          <router-link tag="li" to="/records/services"
            ><a>Usługi</a></router-link
          >
          <router-link
            tag="li"
            :to="{
              name: 'ServicesDetails',
              params: { id: this.$route.params.id },
            }"
            class="is-active"
            ><a>{{ name }}</a></router-link
          >
        </ul>
      </nav>
    </portal-target>
    <div class="ym-toptitle-box">
      <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
        <div id="toptitle" class="level ym-toptitle">
          <div class="level-left">
            <router-link
              tag="a"
              to="/records/services/"
              class="button xbtn is-medium is-transparent"
              ><icon name="x"></icon
            ></router-link>
            <h2 class="title level-left">{{ name }}</h2>
          </div>
          <div class="buttons level-right">
            <button @click="removeServices" class="button is-medium is-light">
              <icon name="trash"></icon><span>Usuń</span>
            </button>
            <router-link
              tag="a"
              :to="{
                name: 'ServicesEdit',
                params: { id: parseInt(this.$route.params.id) },
              }"
              class="button is-medium is-light"
              ><icon name="edit"></icon><span>Edytuj</span></router-link
            >
            <a
              v-if="selectADevice"
              @click="selectDevice"
              class="button is-medium is-dark"
              ><icon name="check"></icon><span>Wybierz</span></a
            >
          </div>
        </div>
      </fixed-header>
    </div>
    <div class="ym-whitebg pa40" v-if="services">
      <div class="columns is-variable is-6">
        <div class="column is-6" v-if="services.category">
          <p class="label-small">Kategoria</p>
          <p class="label">{{ services.category }}</p>
        </div>
        <div class="column is-6" v-if="services.name">
          <p class="label-small">Nazwa</p>
          <p class="label">{{ services.name }}</p>
        </div>
      </div>

      <div class="columns is-variable is-multiline is-6">
        <div class="column is-6" v-if="services.price">
          <p class="label-small">Cena</p>
          <p class="label">{{ services.price }} zł</p>
        </div>
        <div class="column is-6" v-if="services.minutes">
          <p class="label-small">Czas w minutach</p>
          <p class="label">{{ services.minutes }}</p>
        </div>
        <div class="column is-6" v-if="services.buy_price">
          <p class="label-small">Cena zakupu materiału</p>
          <p class="label">{{ services.buy_price }} zł</p>
        </div>
      </div>
      <div class="columns is-variable is-6" v-if="services.note">
        <div class="column is-12">
          <p class="label-small">Notatka</p>
          <p class="label">{{ services.note }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import YMmodal from "@/components/Modal.vue";

export default {
  name: "ServicesDetails",
  components: {
    FixedHeader,
  },
  data: function () {
    return {
      services: {},
    };
  },
  mounted() {
    this.getService();
  },
  computed: {
    ...mapGetters({
      selectADevice: "orders/selectADevice",
      order: "orders/order",
    }),
    name: function () {
      return this.services.name;
    },
  },
  methods: {
    ...mapActions({
      getServiceCall: "records/getService",
      removeServicesCall: "records/removeServices",
    }),
    getService() {
      this.getServiceCall({ id: this.$route.params.id })
        .then((resp) => (this.services = resp))
        .catch((error) => {
          this.services = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    removeServices() {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz usunąć usługę?",
          content: `<span class='is-danger'>Usunięcia usługi nie można cofnąć.</span> Usługa pozostanie w utworzonych do tej pory zleceniach.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "trash",
          submit: `<span class="mal50i">Usuń</span>`,
          ymConfirm: true,
          ymOnConfirm: () => {
            this.removeServicesCall({ id: this.$route.params.id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.$router.push({ name: "Machinery" });
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    selectDevice() {
      this.$store.dispatch("orders/setDevice", this.machinery).then(() => {
        if (this.order && this.order.id) {
          this.$router.push({
            name: "ServiceEdit",
            params: { id: parseInt(this.order.id) },
          });
        } else {
          this.$router.push({ name: "ServiceAdd" });
        }
      });
    },
    statusName: function () {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === this.machinery.order.status_id
      );
      if (status) {
        return status[0].name;
      }
    },
    statusColor: function () {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === this.machinery.order.status_id
      );
      if (status.length > 0) {
        return `color: ${status[0].colour}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
